// body,html{
//   // height: 100%;
  
// }
@keyframes spinner-border {
  to { transform: rotate(360deg); }
}
.spinner-box{
  display: none;
  &.show{
    display: block;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba($color: #000000, $alpha: .85);
    .spinner-group{
      position: absolute;
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
    }
  }
}
.spinner-border{
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25rem solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
}
.spinner-border-sm {
  width: $spinner-width-sm;
  height: $spinner-height-sm;
  border-width: $spinner-border-width-sm;
}

.wrapper{
  margin-top: 65px;
  margin-bottom:52px
}
.font-14{
  font-size: 14px;
}
.font-13{
  font-size: 13px;
}
.service-color{
  color: rgba($color: $white, $alpha: .7);
  font-weight: normal;
  padding-left: 1.25rem;
  font-size: .85rem;
  line-height: 1.25rem;
  li{
    margin-bottom: 1rem;
  }
}
.wrapper-box{
  margin-top: 5rem;
  margin-bottom: 5rem;
  @include media-breakpoint-up(lg){
    margin-top: 7rem;
    margin-bottom:1.875rem;
  }
}
.kw-margin{
  margin-top: 50px;
  margin-bottom: 120px;
}


.btn{
  color: $white;
}
.mobile_logo{
  max-width: 82px;
  @include media-breakpoint-up(lg){
    max-width: 160px;
  }
}
// 灰色线框按钮
.btn-outline-sec{
  border: 1px solid hsla(0,0%,100%,.35);
  color: $white;
  &:hover{border: 1px solid hsla(0,0%,100%,1);}
}

.btn-m-dm{
  background-color: $dark;
  color: $white;
  text-align: center;
  padding: .75rem 0;
  width: 50%;
  height: 100%;
}
.btn-m-main{
  background-color: $primary;
  color: $white;
  text-align: center;
  padding: .75rem 0;
  width: 50%;
  height: 100%;

}
.top-shadow{
  box-shadow: 0 -3px 5px 5px rgba($color: #000000, $alpha: .2);
}

.tool-group{
  width: 100%;
  display: flex;
  .btn-m-dm{
    color: rgba($color: $white, $alpha: .3);
  }
  .btn-tool{
    padding-top: .25rem;
    padding-bottom: .25rem;
    text-align: center;
    i.fas{
      font-size: 1.25rem;
      margin-bottom: 2px;
      margin-top: 4px;

    }
    span{
      display: block;
      font-size: 12px;
    }
  }
  .btn-m-dm.active{
    color: $primary;
  }
}

// navbar
.block-balance{
  min-width: 110px;
  text-align: left;
  
}

.kw-mt{
  margin-top: 60px;
  @include media-breakpoint-up(lg) {
    margin-top: 55px;
  }
}

// 装饰
.left-arrow{
  height: 2px;
  background-image: linear-gradient(to right,rgba(0,0,0,0) 0,#ffcf68 50%,#FF9709 100%);
}
.right-arrow{
  height: 2px;
  background-image: linear-gradient(to left,rgba(0,0,0,0) 0,#ffcf68 50%,#FF9709 100%);
}

// form 亮色版
.form-light{
  label{color:$gray-800};
  .form-control{
    background-color: #fff;
    border: 1px solid #ADADAD;
    color: $gray-800;
  }
}

.info-box{
  border: 1px solid rgba($color: $white, $alpha: .3);
  border-radius: .5rem;
  padding: 1rem .625rem;
  @include media-breakpoint-up(lg) {
    padding: 1rem;
  }
}

.user-avatar{
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50rem;
  border: 3px solid rgba($color: $white, $alpha: .8);
  img{
    border-radius: 50rem;
  }
}
.member-info{
  text-align: center;
  margin-top: .5rem;
}
.address-info{
  margin: 1rem 0;
}
hr.hr-light{
  border-top: 1px solid rgba($white, .1);
}
.current-balance,.address-block{
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba($color: $white, $alpha: .3);
}
.address{
  font-size: 14px;
  word-wrap: break-word;
  margin-bottom: .5rem;
}
.warning-box{
  padding: 10px 1rem;
  border: 1px solid rgba($color: $white, $alpha: .3);
  border-radius: .5rem;  
  background-color: rgba($color: $white, $alpha: .05);
}

// 歷程
ul.game-list{
  list-style: none;
  padding-left: 0;
  li{
    background-color: rgba($color: $white, $alpha: .2);
    border-radius: .5rem;
    padding: .625rem;
    box-shadow: 0 5px 5px rgba($color: #000000, $alpha: .15);

  }
}
.table-nowrap{
  th,td{
    white-space: nowrap;
    vertical-align:bottom;
    h6{
      margin-bottom: 0;
    }
  }
}
.bulb{
  display: inline-block;
  vertical-align:middle;
  width:14px;
  height: 14px;
  border-radius: 50rem;
  background-color: rgba($color: $white, $alpha: .2);
  margin-right: .25rem;
  border: 2px solid rgba($color: $white, $alpha: .5);
  &.bulb-cancel{
    background-color: $danger;
  }
  &.bulb-process{
    background-color: $primary;
  }
  &.bulb-success{
    background-color: $success;
  }
}


.pc-navbar{
  display: none;
  flex-direction: row;
  margin-left: 1.5rem;
  &.show{
    @include media-breakpoint-up(lg){
      display: flex;
    }
  }
}
.nologin-group{
  display: none;
  @include media-breakpoint-up(lg){
    display: none;
  }
  &.show{
    @include media-breakpoint-up(lg){
      display: block;
    }
  
  }
}
.nav-activity{
  display: none;
  margin-left: auto;
  &.show{
    display: block;
  }
}

.tool-group{
  display: none;
  &.show{
    @include media-breakpoint-down(lg){
      display: flex;
    }
  }
}


// Check style
.AgreementMandatoryComponent{
  position: relative;
  margin-bottom: 10px;
  padding-right: 10px;
  .checkboxView-title{
    padding-left: 10px;
    display: inline;
    cursor: pointer;
    user-select: none;
  }
  .checkboxView{
    display: flex;
    vertical-align: -3px;
    line-height: 1.25rem;
    input.checkboxView-toggle{
      display: none;
      &:invalid{
        box-shadow: none;
      }
    }
    .checkboxView-label{
      min-width: 20px;
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: top;
      background-color: $main-dark;
      border: 1px solid rgba(222, 223, 227, 0.548);
      cursor: pointer;
      border-radius: 2px;
      transition: background-color .4s ease;
      &.ink-ripple{
        position: absolute;
        display: block;
        left: -15px;
        right: -15px;
        top: -15px;
        bottom:-15px;
        width: auto;
        height: auto;
        pointer-events: none;
        overflow: hidden;
      }
    }
  }
}
.checkboxView-toggle:checked+.checkboxView-label, .checkboxView-toggle:checked+.checkboxView-title+.checkboxView-label{
  border: 1px solid $primary;
  background: $primary url('../images/check.svg') no-repeat 0px -.5px;
  background-size: 18px;
}

#pills-tabContent{
  img{
    width: 100%;
  }
}
.item-fix{
  position: fixed;
  bottom: 60px;
  right: 15px;
  z-index: 9999;
  &.customer{
    bottom: 120px;
    right: 15px;
    }
  .btn-light{
    background-color: rgba(255,255,255,.2);
    border-color: rgba(255,255,255,.4);
    // padding: .5rem;
    // border-radius: 50rem;
    width: 44px;
    height: 44px;
  }
}

// 阴影
#pills-tabContent-gameList{
  img{
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
    transition: all .2s ease-in-out 0s;
    &:hover{
      transform: scale(1.03);
    }
  }
}

// @include media-breakpoint-up(sx) {
//   .container.kw-margin{
//     max-width: initial;
//     width: 100%;
//     padding-left: 5%;
//     padding-right: 5%;
//   }
// }

// 跑馬燈
.important-message{
  position: fixed;
  z-index: 1031;
  // top: 100px;
  left: 50%;
  width: 60%;
  // background-color: rgba($color: #000000, $alpha: .5);
  transform: translateX(-50%);
  // margin: 4px 1rem;
  @include media-breakpoint-down(xs) {width:90%;}
  overflow: hidden;
}

.carousel-inner{
  background: #241e35!important;
  padding-top: 30px;
}

//跑馬燈rwd
@media(max-width: 992px){
  .important-message{
    position: absolute;
    z-index: 1;
    top: 65px;
  }
}
.carousel-control-prev, .carousel-control-next {
  top:35px;
}