.buycoin{
    img{
        border: 1px solid #38334c;
        border-radius: 10px;
    }
}


// gameinfo的css
.bg{
    background-image: url(../images/lang/bg.png);
    background-attachment:fixed;
    width: 100%;
    min-height: 100%;
    background-size: cover;
    background-color: rgba(0, 0, 0, .7);
    background-blend-mode: multiply;
    background-position: center center;
    background-repeat: no-repeat;
}

.info-box{
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: .5rem;
    position: relative;
      
}
.close{
        // position: absolute;
        font-size: 30px;
        background-color: #ffc31e;
        border-radius: 999rem;
        height: 35px;
        width: 35px;
        text-align: center;
        opacity: 1;
}  
.close:hover{
    background-color: #ffae34;
    opacity: 1;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: 1;
}
.sticky-top{
    top: 30px;
}

// 1119 新增playnow跟遊戲試玩按鈕
.col-6.col-md-4.col-lg-3.mb-3{
    position: relative;
    img.img-fluid.game{
        
    }
    .button2{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        opacity: 0;
        a.gamedemo{
            color: rgba(255, 255, 255, 0.8);
        }
    }
}
.col-6.col-md-4.col-lg-3.mb-3:hover{
    img.img-fluid.game{
        filter:brightness(.5); 
        transition: .2s;
        
    }
    .button2{
        opacity: 1;
        a.gamedemo{
            color: rgba(255, 255, 255, 0.7);
            text-decoration: none;
            
        }
        a.gamedemo:hover{
            cursor: pointer;
            color: #fff;
            text-decoration: none;
        }
    }
}


.breadcrumb{
    background-color: transparent !important;
}


@media(min-width: 992px){
    .buycoin{
        img{
            display: none;
        }
    }

    .col-lg-20{
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        flex: 20%;
        max-width: 20%;  
      }
}
.buycoin{
    a{
        display: inline-block;
    }

    

}



@media(max-width:425px){
    // .game:hover{
    //     a.btn.btn-primary.text-white.start-game{
    //             top: 40%;
    // }
    // }
    // a.gamedemo{
    //     bottom: 0;
    // }
}
